<template>
  <div>
    <Modal v-model="modifyModal" :mask-closable="false" width="600">
      <p slot="header" class="text-center">{{ opType === 1 ? '新增' : '编辑' }}套装媒体产品</p>
      <Form ref="formValidate" :model="productModel" :rules="ruleValidate" :label-width="140">
        <FormItem label="名称" prop="productskuName">
          <Input v-model="productModel.productskuName" size="small" placeholder="填写名称" />
        </FormItem>
        <FormItem label="最低限价" prop="statementPrice">
          <InputNumber :min="0" v-model="productModel.statementPrice"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入最低限价">
          </InputNumber>
        </FormItem>
        <FormItem label="选择单品">
          <Button type="primary" size="small" @click="handleChoiceItem">选择单品</Button>
        </FormItem>
        <FormItem v-show="selectedSkuArray.length">
          <Table size="small" stripe :data="selectedSkuArray" :columns="selectedSkuColumns"></Table>
        </FormItem>
        <FormItem label="最小售卖天数" prop="minday">
          <InputNumber v-model="productModel.minday" :min="1" size="small" style="width:100%" placeholder="最小售卖天数">
          </InputNumber>
        </FormItem>
        <FormItem label="同一个站点最大资源数" prop="minday">
          <InputNumber v-model="productModel.stationMax" :min="1" size="small" style="width:100%"
            placeholder="同一个站点最大资源数"></InputNumber>
        </FormItem>
        <FormItem label="是否可用">
          <i-switch size="large" v-model="productModel.enabled">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="modifyModal = false">取消</Button>
        <Button type="primary" @click="handleSave">确定</Button>
      </div>
    </Modal>
    <Modal v-model="skuModal" :mask-closable="false" width="600">
      <p slot="header" class="text-center">选择单品</p>
      <Row :gutter="8" class="m-b-5">
        <i-col span="6">
          <Select v-model="assetId" size="small" placeholder="选择资产">
            <Option v-for="item in assetArray" :key="'aId_' + item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </i-col>
        <i-col span="6">
          <Select v-model="skuQuery.enabled" :clearable="true" size="small" placeholder="是否可用">
            <Option v-for="item in enabledArray" :key="'en_' + item.value" :value="item.value">{{ item.name }}</Option>
          </Select>
        </i-col>
        <i-col span="4">
          <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
        </i-col>
      </Row>
      <Table size="small" stripe height="600" :data="skuList" :columns="skuColumns"></Table>
      <div class="paging_style">
        <Page size="small" :total="skuTotal" :page-size="skuQuery.pageSize" show-total show-elevator
          :current="skuQuery.pageNumber" @on-change="handlePageChange"></Page>
      </div>

    </Modal>
    <Modal v-model="skuAddModal" :mask-closable="false" width="600">
      <p slot="header" class="text-center">单品设置</p>
      <Form ref="skuValidate" :model="skuSettingModel" :rules="skuSettingValidate" :label-width="140">
        <FormItem label="价格" prop="price">
          <InputNumber :min="0" v-model="skuSettingModel.price"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入价格">
          </InputNumber>
        </FormItem>
        <FormItem label="数量" prop="quantity">
          <InputNumber :min="0" v-model="skuSettingModel.quantity" size="small" style="width:100%" placeholder="输入数量">
          </InputNumber>
        </FormItem>
        <!-- <FormItem
          label="选择资产"
          prop='assetIds'
        >
          <Select
            v-model="skuSettingModel.assetIds"
            multiple
            size="small"
            placeholder="选择资产"
          >
            <Option
              v-for="item in curSkuAssetArray"
              :key="'asId_'+item.id"
              :value="item.id"
            >{{item.name}}</Option>
          </Select>
        </FormItem> -->
      </Form>
      <div slot="footer">
        <Button type="text" @click="skuAddModal = false">取消</Button>
        <Button type="primary" @click="handleSaveSkuSetting">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { productMixins } from '../mixins/product'
import { getAllSkuPage, addCustom, editCustom } from '@/api/product/productskuedit'
export default {
  mixins: [productMixins],
  props: {
    saveSuccessFun: Function
  },
  data () {
    return {
      modifyModal: false,
      productModel: {
        assetIds: [],
        productskuName: '',
        enabled: true,
        minday: 1,
        skuinfos: '',
        desc: '',
        stationMax: 1,
        cityCode: '',
        cityName: '',
        statementPrice: -1 // 最低限价
      },
      productId: null,
      productskuId: null,
      ruleValidate: {
        productskuName: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        statementPrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' },
          { pattern: /(^\d+(\.{0,1}\d+){0,1}$)/, message: ' ' }
        ]
      },
      enabledArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' }
      ],
      skuModal: false,
      assetId: null,
      skuQuery: {
        pageNumber: 1,
        pageSize: 15,
        assetIds: '',
        categoryId: null,
        enabled: null,
        productId: null,
        type: null
      },
      skuList: [],
      skuTotal: 0,
      skuColumns: [
        { title: '名称', align: 'center', key: 'name' },
        {
          title: '资产',
          align: 'center',
          render: (h, params) => {
            const html = []
            params.row.assets.forEach(item => {
              html.push(h('p', item.name))
            })
            return h('div', html)
          }
        },
        {
          title: '是否可用',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.enabled ? '可用' : '不可用')
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleAddSku(params.row)
                }
              }
            }, '加入')
          }
        }
      ],
      skuAddModal: false,
      curChooiceSku: null,
      skuSettingModel: {
        id: null,
        quantity: 0,
        price: 0
        // assetIds: []
      },
      // curSkuAssetArray: [],
      selectedSkuArray: [],
      selectedSkuColumns: [
        { title: '名称', align: 'center', key: 'name' },
        { title: '数量', align: 'center', key: 'quantity' },
        {
          title: '价格',
          align: 'center',
          key: 'price',
          render: (h, params) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, toMoney(params.row.price))
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              style: { color: '#ef4f4f' },
              on: {
                click: () => {
                  this.handleDelSkuItem(params)
                }
              }
            }, '删除')
          }
        }
      ],
      selectedAssetIds: [],
      skuSettingValidate: {
        price: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        quantity: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ]
        // assetIds: [
        //   { required: true, type: 'array', min: 1, message: ' ', trigger: 'change' }
        // ]

      }
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.productManage.assetArray
    },
    opType () {
      return this.$store.state.productManage.opType
    }
  },
  mounted () {
    this.getCompanyInfo()
  },
  methods: {
    initShow (data) {
      this.assetId = this.assetArray[0].id || null
      if (this.opType === 1) {
        // 新增
        this.selectedSkuArray = []
        this.selectedAssetIds = []
        Object.assign(this.$data.productModel, this.$options.data().productModel)
      } else {
        // 编辑
        if (data) {
          Object.keys(this.productModel).forEach(key => {
            this.productModel[key] = data[key]
          })
          this.productModel.assetIds = data.assetIdList
          this.productModel.productskuName = data.name
          this.productId = data.productId
          this.productskuId = data.id
          this.selectedAssetIds = data.assetIdList

          // this.productModel.productskuName = data.name
          // this.productModel.assetIds = data.assetIdList
          // this.productModel.skuinfos = data.skuinfos
          // this.productModel.minday = data.minday
          // this.productModel.enabled = data.enabled
          // this.productModel.desc = data.desc
          // this.productModel.stationMax = data.stationMax
          // this.productModel.cityCode = data.cityCode
          // this.productModel.cityName = data.cityName
          // this.productId = data.productId
          // this.productskuId = data.id

          this.selectedSkuArray = data.custom.map(x => {
            return {
              id: x.productskuId,
              name: x.productskuName,
              quantity: x.quantity,
              price: x.usePrice
            }
          })
        }
      }
      this.modifyModal = true
    },
    handleChoiceItem () {
      this.skuModal = true
      this.skuQuery.pageNumber = 1
      this.getAllSkuData()
    },
    getAllSkuData () {
      this.skuQuery.assetIds = JSON.stringify([this.assetId])
      getAllSkuPage(this.skuQuery).then(res => {
        if (res && !res.errcode) {
          this.skuList = res.list
          this.skuTotal = res.totalRow
        } else {
          this.skuList = []
          this.skuTotal = 0
        }
      })
    },
    handlePageChange (curPage) {
      this.skuQuery.pageNumber = curPage
      this.getAllSkuData()
    },
    handleSearch () {
      this.skuQuery.pageNumber = 1
      this.getAllSkuData()
    },
    /**
     * 选择单品加入
     */
    handleAddSku (params) {
      Object.assign(this.$data.skuSettingModel, this.$options.data().skuSettingModel)
      this.curChooiceSku = params
      // this.curSkuAssetArray = params.assets
      this.skuSettingModel.id = params.id
      this.skuAddModal = true
    },
    /**
     * 删除选中的单品数据
     */
    handleDelSkuItem (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除当前项？',
        onOk: () => {
          this.selectedSkuArray.splice(params.index, 1)
        }
      })
    },
    /**
     * 保存单品设置
     */
    handleSaveSkuSetting () {
      this.$refs.skuValidate.validate(valid => {
        if (valid) {
          if (this.selectedSkuArray.some(x => x.id === this.skuSettingModel.id)) {
            this.$Notice.warning({ desc: '当前单品已存在，请重新选择' })
          } else {
            const obj = Object.assign({ name: this.curChooiceSku.name }, this.skuSettingModel)
            this.selectedSkuArray.push(obj)
            this.selectedAssetIds = this.selectedAssetIds.concat(this.curChooiceSku.assetIdList)
          }

          this.skuAddModal = false
          this.skuModal = false
        }
      })
    },
    /**
     * 保存信息
     */
    handleSave () {
      this.$refs.formValidate.validate(valid => {
        if (valid) {
          // 组装数据
          const assetIds = Array.from(new Set(this.selectedAssetIds))
          const skuInfos = this.selectedSkuArray.map(x => {
            return {
              s: x.id,
              q: x.quantity,
              p: x.price
            }
          })

          this.$Modal.confirm({
            title: '操作提示',
            content: '确定信息无误，并提交？',
            onOk: () => {
              this.productModel.assetIds = JSON.stringify(assetIds)
              this.productModel.skuinfos = JSON.stringify(skuInfos)
              if (this.opType === 1) {
                this.productModel.cityCode = this.companyInfo.cityCode
                this.productModel.cityName = this.companyInfo.cityName

                addCustom(this.productModel).then(res => {
                  this.modifyModal = false
                  if (res && res.errcode === 0) {
                    this.$Notice.success({ desc: '操作成功' })
                    this.saveSuccessFun()
                  }
                })
              } else {
                const postData = Object.assign({ productskuId: this.productskuId, productId: this.productId }, this.productModel)
                editCustom(postData).then(res => {
                  this.modifyModal = false
                  if (res && res.errcode === 0) {
                    this.productskuId = null
                    this.productId = null
                    this.$Notice.success({ desc: '操作成功' })
                    this.saveSuccessFun()
                  }
                })
              }
            }
          })
        }
      })
    }
  }
}
</script>
